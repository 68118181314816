import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import MainEvents from './MainEvents';
import MainIntro from './MainIntro';

const Main = (): JSX.Element => {
  const data = useStaticQuery(graphql`
  query {
    contentfulAsset(title: {eq: "Path 30"}) {
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
  `);
  return (
    <div className="main">
      <MainIntro />
      <MainEvents />
      <Img className="wave wave--bottom" fluid={data.contentfulAsset.fluid} />
      <div className="main__anchor" id="materials"></div>
    </div>
  );
};

export default Main;