import { ChevronRight } from '@material-ui/icons';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const MainIntro = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "Group 2"}) {
        fluid(maxWidth: 871) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);
  
  return (
    <div className="main-intro">
      <div>
        <h1>NOWY WYMIAR DYSKUSJI O NAUCE</h1>
        <p className="main-intro__text">
        Dla kogoś, kto nie ma na co dzień do czynienia z nauką, proces naukowy może wydawać się czymś
        tajemniczym i niezrozumiałym. Żeby temu zaradzić, postanowiliśmy przybliżyć Wam mechanizmy 
        powstawania wiedzy naukowej. To, co dotychczas było zarezerwowane jedynie dla naukowców, teraz
        będzie dostępne dla każdego, dzięki dyskusjom na grafach argumentacji.
        </p>
        <div className="main-intro__footer">
          <Link to="/dyskusje">
            <div className="main-intro__footer-icon">
              <ChevronRight fontSize="inherit" />
            </div>
          </Link>
          <Link to="/dyskusje">
            <h2>ZOBACZ DYSKUSJE</h2>
          </Link>
          <Link to="/projekt">
            <h3>O PROJEKCIE</h3>
          </Link>
        </div>
      </div>
      <div className="main-intro__phone">
        <Img className="main-intro__phone-img" fluid={data.contentfulAsset.fluid} />
        <button>NAUKA</button>
      </div>
    </div>
  );
};

export default MainIntro;