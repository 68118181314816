import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';

const NavButton = ({ previous, next }): JSX.Element => {
  return (
    <div className="nav-button">
      <button className="nav-button--prev" onClick={() => previous()}>
        <ChevronLeft fontSize="inherit" />
      </button>
      <button className="nav-button--next" onClick={() => next()}>
        <ChevronRight fontSize="inherit" />
      </button>
    </div>
  );
};

export default NavButton;