import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import Materials from './Materials';
import ProjectInfo from './ProjectInfo';

const Section = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "Path 210"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);
  return (
    <div className="middle">
      <Materials />
      <ProjectInfo />
      <Img className="wave wave--bottom" fluid={data.contentfulAsset.fluid} />
    </div>
  );
};

export default Section;