import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ChevronRight } from '@material-ui/icons';
import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { ContentfulMaterialNode } from 'models/contentfulModels';

import NavButton from './NavButton';

const Materials = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      materials: allContentfulMaterials(sort: {fields: createdAt, order: DESC}, limit: 6) {
        edges {
          node {
            slug
            title
            category
            externalLink
            image {
              file {
                url
              }
            }
            description {
              json
            }
          }
        }
      }
    }
  `);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1680 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1680, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="materials">
      <div className="materials__tiles">
        <Carousel 
          responsive={responsive} 
          infinite={true} 
          arrows={false} 
          customButtonGroup={<NavButton />}
        >
          {data.materials.edges.map((item: ContentfulMaterialNode) => {
            return (
              <a 
                key={item.node.slug} 
                href={item.node.category === 'Infografika' ? `/materiały/${item.node.slug}` : item.node.externalLink}
                rel="noopener noreferrer" 
                target="_blank"
                className="materials__tile"
              >
                <img alt={item.node.title} className="materials__img" src={`https:${item.node.image.file.url}`} />
                <h1>
                  {item.node.category}
                </h1>
                <h2>
                  {item.node.title}
                </h2>
                <div className="materials__desc">
                  {documentToReactComponents(item.node.description.json)}
                </div>
              </a>
            );
          })}
        </Carousel>
      </div>
      <div className="materials__text">
        <h1>MATERIAŁY KTÓRE CIĘ ZAINSPIRUJĄ!</h1>
        <p>
          Przejrzyj materiały tworzone przez naszych użytkowników, specjalistów i 
          prelegentów. Poznaj naukę z różnych perspektyw.
        </p>
        <Link to="/materiały">
          <div className="materials__link-icon">
            <ChevronRight fontSize="inherit" />
          </div>
          <h1>WIĘCEJ MATERIAŁÓW</h1>            
        </Link>
      </div>
    </div>
  );
};

export default Materials;