import React from 'react';

const MainEvents = (): JSX.Element => {
  
  return (
    <div className="main-events" id="events">
      <iframe 
        className="main-events__video" 
        title="tutorial" 
        src="https://www.youtube.com/embed/rV2f8Ii1JpQ"
        frameBorder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen>
      </iframe>
    </div>
  );
};
export default MainEvents;