import React from 'react';

import 'styles/main.scss';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import Main from 'components/Main';
import Section from 'components/Section';
import SEO from 'components/SEO';


const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Home" />
      <Main />
      <Section />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
