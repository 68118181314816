import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';

import { ContentfulProjectNode } from 'models/contentfulModels';

const ProjectInfo = (): JSX.Element => {

  const [buttons, setButtons] = useState([1, 0, 0]);
  const toggleClick = (id: number): void => {
    id === 0 && setButtons([1, 0, 0]);
    id === 1 && setButtons([0, 1, 0]);
    id === 2 && setButtons([0, 0, 1]);
  };

  const data = useStaticQuery(graphql`
    query {
      allContentfulProject(limit: 3, sort: {fields: createdAt}) {
        edges {
          node {
            id
            category
            title
            image {
              file {
                url
              }
            }
            description {
              description
            }
          }
        }
      }
    }
  `);
  const contentfulProjectNodes = data.allContentfulProject.edges as ContentfulProjectNode[];
  const linkHeader = <h1>{`Projekt realizuje `} 
    <a 
      href="https://pl-pl.facebook.com/optimumparetofundacja/" 
      rel="noopener noreferrer" 
      target="_blank"
      className="project__text__link"
    >
      fundacja optimum pareto
    </a> 
  </h1>;
  return (
    <div className="project" id="project">
      {contentfulProjectNodes.map((item, id) => (
        <div 
          key={item.node.id} 
          className={`project__text${buttons[id] ? `--on` : `--off`}`}
        >
          {item.node.category === 'O nas' ? linkHeader : <h1>{item.node.title}</h1>}
          <p>{item.node.description.description}</p>
        </div>
      ))}
      <div className="project__box">
        {contentfulProjectNodes.map((item, id) => (
          <img 
            alt={item.node.title}
            key={item.node.id} 
            className={`project__box-img${buttons[id] ? `--on` : `--off`}`} 
            src={`https:${item.node.image.file.url}`} 
          />
        ))}
      </div>
      <div className="project__controls">
        {contentfulProjectNodes.map((item, id) => (
          <button 
            key={item.node.id}
            className={`project__button${buttons[id] ? `--on` : `--off`}`}
            onClick={(): void => toggleClick(id)}
          >
            {item.node.category}
          </button>
        ))}
      </div>
      <h1 className="project__header">DOWIEDZ SIĘ WIĘCEJ!</h1>
      <div className="project__block">
        {contentfulProjectNodes.map((item, id) => (
          <div 
            key={item.node.id} 
            className={`project__block-text${buttons[id] ? `--on` : `--off`}`}
          >
            <h1>{item.node.title}</h1>
            <p>{item.node.description.description}</p>
          </div>
        ))}
        <div className="project__block-controls">
          {contentfulProjectNodes.map((item, id) => (
            <button 
              key={item.node.id}
              className={`project__block-button${buttons[id] ? `--on` : `--off`}`}
              onClick={(): void => toggleClick(id)}
            >
              {item.node.category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;